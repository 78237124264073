import { stringify } from "query-string";

import { AxiosInstance } from "@bps/http-client";
import { IF_MATCH_HEADER } from "@libs/api/api.constants.ts";
import { GetStagingInfoDto, PagingOptions } from "@libs/api/dtos/index.ts";
import { excludedRefDataFields, RefDataDto } from "@libs/api/ref-data/dto.ts";
import {
  toQueryResult,
  withoutFields,
  withPaging
} from "@libs/api/utils/paging.utils.ts";
import {
  AddAmendmentDto,
  AddEncounterDto,
  AmendmentDto,
  ClinicalActivityDto,
  ClinicalActivityUnlockArgs,
  ClinicalFlagsDto,
  ClinicalNoteSectionElement,
  ClinicalRecordDto,
  ClinicalTaskDto,
  ClinicalTasksUnlockArgs,
  CodedText,
  DocumentContentDto,
  DocumentCreateOptions,
  DocumentDeleteArgsDto,
  DocumentDto,
  DocumentMoveArgsDto,
  DocumentUrlDto,
  EncounterClinicalDataDto,
  EncounterClinicalNoteDto,
  EncounterDto,
  EncounterSearchDto,
  EncounterTimerDto,
  EncounterTimerOperationDto,
  EpisodeOfCareClinicalDataDto,
  EpisodeOfCareDto,
  GetClinicalActivitiesDto,
  GetClinicalTasksDto,
  GetDocumentsDto,
  GetDocumentUrlArgs,
  GetEncounterClinicalDataDto,
  GetEncountersDto,
  GetEpisodeOfCareClinicalDataDto,
  GetEpisodesOfCareClinicalDataDto,
  GetInteractionsDto,
  GetPatientClinicalDataDto,
  GetReactionWarningsArgs,
  GetReasonForVisitClinicalDto,
  GetStructuredNoteDto,
  ImagingModalityRefDataDto,
  ImagingRegionsRefDataDto,
  InjuryArea,
  IntakeFlagRefDataDto,
  IntakeFrequency,
  IntakeStatus,
  IntakeType,
  IntakeUnit,
  InteractionDto,
  MeasurementDto,
  MeasurementsFilter,
  NervesDto,
  ObservationDto,
  PatchEncounterDto,
  QuestionnaireDto,
  ReactionWarningDto,
  RefAcupointDto,
  RefClinicalActivityDescriptionDto,
  RefClinicalActivityTypeDto,
  RefClinicalDataTypeDto,
  RefClinicalTaskTypeDto,
  RefCorrespondenceTypeDto,
  RefEncounterTypeDto,
  RefInjuryAreaMotionTypeGroupDto,
  RefMeridianDto,
  RefMotionTypeDto,
  RefReactionTypeDto,
  RefSexualityDto,
  RefTreatmentOptionDto,
  SendClinicalDocumentArgs,
  SpecialTestDto,
  StructuredNoteDto,
  SubstanceUseTypeDto,
  SubstanceUseTypeFilter,
  TerminologyConceptDto,
  TerminologyLookupRequest,
  TerminologySearchRequest,
  UserIdentifier
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { IClinicalGateway } from "@libs/gateways/clinical/ClinicalGateway.interface.ts";
import { QueryResult } from "@libs/utils/promise-observable/promise-observable.utils.ts";

/**
 * Gateway class that is used to communicate with Clinical backend API
 */
export class ClinicalGateway implements IClinicalGateway {
  constructor(private api: AxiosInstance) {}

  async getClinicalRecord(id: string): Promise<ClinicalRecordDto> {
    const excludedFields = ["changeLog"];

    const response = await this.api.get<ClinicalRecordDto>(
      `/patients/${id}/clinical?${withoutFields(excludedFields)}`
    );
    return response.data;
  }

  async getSubstanceUseTypes(
    filter: SubstanceUseTypeFilter
  ): Promise<QueryResult<SubstanceUseTypeDto>> {
    return await this.api
      .get<SubstanceUseTypeDto[]>(
        `/SubstanceUseType?${stringify(withPaging(filter))}`
      )
      .then(toQueryResult);
  }

  getEncounter(id: string): Promise<EncounterDto> {
    return this.api.get<EncounterDto>(`/encounters/${id}`).then(x => x.data);
  }

  getMeasurement(id: string): Promise<MeasurementDto> {
    return this.api
      .get<MeasurementDto>(`/measurements/${id}`)
      .then(x => x.data);
  }

  getMeasurements(
    request: MeasurementsFilter & PagingOptions
  ): Promise<QueryResult<MeasurementDto>> {
    const { patientId, ...requestRest } = request;

    const queryString = withPaging(requestRest);

    return this.api
      .get<MeasurementDto[]>(
        `/patients/${patientId}/measurements?${stringify(queryString)}`
      )
      .then(x => toQueryResult(x));
  }

  updateMeasurement(data: MeasurementDto): Promise<MeasurementDto> {
    return this.api
      .put<MeasurementDto>(`/measurements/${data.id}`, data)
      .then(x => x.data);
  }

  getObservation(id: string): Promise<ObservationDto> {
    return this.api
      .get<ObservationDto>(`/observations/${id}`)
      .then(x => x.data);
  }

  getObservations(
    request: MeasurementsFilter & PagingOptions
  ): Promise<QueryResult<ObservationDto>> {
    const { patientId, ...requestRest } = request;

    const queryString = withPaging(requestRest);

    return this.api
      .get<ObservationDto[]>(
        `/patients/${patientId}/observations?${stringify(queryString)}`
      )
      .then(x => toQueryResult(x));
  }

  updateObservation(data: ObservationDto): Promise<ObservationDto> {
    return this.api
      .put<ObservationDto>(`/observations/${data.id}`, data)
      .then(x => x.data);
  }

  getInteraction(id: string): Promise<InteractionDto> {
    return this.api
      .get<InteractionDto>(`/interactions/${id}`)
      .then(x => x.data);
  }

  getCorrespondenceTypes() {
    return this.getRef<RefCorrespondenceTypeDto>("correspondenceTypes");
  }

  getCorrespondenceDirections() {
    return this.getRef("correspondenceDirections");
  }

  getCorrespondenceStatuses() {
    return this.getRef("correspondenceStatuses");
  }

  getEyeVisualAcuities() {
    return this.getRef("visualAcuities");
  }

  getReasonsForDiscard() {
    return this.getRef("reasonsForDiscard");
  }

  getReasonsForDeleteReaction() {
    return this.getRef("reactionDeleteReasons");
  }

  getReasonsForDeleteMedication() {
    return this.getRef("medicationDeleteReasons");
  }

  getReasonsForDeleteCorrespondence() {
    return this.getRef("correspondenceDeleteReasons");
  }

  getDrinkFrequencies() {
    return this.getRef("drinkFrequencies");
  }

  getDosingAdministrationUnits() {
    return this.getRef("dosingAdministrationUnits");
  }

  getDosingDurationPeriods() {
    return this.getRef("dosingDurationPeriods");
  }

  getDosingFood() {
    return this.getRef("dosingFood");
  }

  getDosingFrequencies() {
    return this.getRef("dosingFrequencies");
  }

  getRxTypes() {
    return this.getRef("rxTypes");
  }

  getDosingOtherInstructions() {
    return this.getRef("dosingOtherInstructions");
  }

  getDosingRoutes() {
    return this.getRef("dosingRoutes");
  }

  getEyeForeignBodies() {
    return this.getRef("visualAcuityForeignBodies");
  }

  getEyeRetinopathies() {
    return this.getRef("retinopathies");
  }

  getMedicalCertainties() {
    return this.getRef("medicalCertainties");
  }

  getSideObservations() {
    return this.getRef("sideOfBody");
  }

  getOccupations() {
    return this.getRef("occupations");
  }

  getWorkTypes() {
    return this.getRef("workTypes");
  }

  getNatureOfReactions() {
    return this.getRef("reactionCodes");
  }

  getReactionSeverity() {
    return this.getRef("reactionSeverity");
  }

  getInteractionTypes() {
    return this.getRef("interactionTypes");
  }

  getReasonsForDeleteMedicalHistory() {
    return this.getRef("medicalHistoryDeleteReasons");
  }

  getReasonsForDelete() {
    return this.getRef("reasonForDelete");
  }

  getReasonsForDeleteInvestigationReport() {
    return this.getRef("investigationReportDeleteReasons");
  }

  getCessationAdvices() {
    return this.getRef("cessationAdvices");
  }

  getPhysicalActivities() {
    return this.getRef("physicalActivities");
  }

  getPhysicalActivityLevels() {
    return this.getRef("physicalActivityLevels");
  }

  getCeasedMedicationReasons() {
    return this.getRef("ceaseMedicationReasons");
  }

  getIntakeStatuses() {
    return this.getRef<IntakeFlagRefDataDto<IntakeStatus>>("intakeStatus");
  }

  getIntakeTypes() {
    return this.getRef<IntakeFlagRefDataDto<IntakeType>>("intakeType");
  }

  getIntakeFrequencies() {
    return this.getRef<RefDataDto<IntakeFrequency>>("intakeFrequency");
  }

  getInvestigations() {
    return this.getRef("investigations");
  }

  getProgressionTypes() {
    return this.getRef("progressionTypes");
  }

  getSwellingTypes() {
    return this.getRef("swellingTypes");
  }

  getStages() {
    return this.getRef("stages");
  }

  getIrritabilityLevels() {
    return this.getRef("irritabilityLevels");
  }

  getNatures() {
    return this.getRef("natures");
  }

  getDailyPatterns() {
    return this.getRef("dailyPatterns");
  }

  getSleepPatterns() {
    return this.getRef("sleepPatterns");
  }

  getAggravatingFactors() {
    return this.getRef("aggravatingFactors");
  }

  getEasingFactors() {
    return this.getRef("easingFactors");
  }

  getTreatmentOptions() {
    return this.getRef<RefTreatmentOptionDto>("treatmentOptions");
  }

  async getMeridians(): Promise<RefMeridianDto[]> {
    return this.getRef<RefMeridianDto>("meridians");
  }
  async getAcupoints(): Promise<RefAcupointDto[]> {
    return this.getRef<RefAcupointDto>("acupoints");
  }

  getEducationOptions() {
    return this.getRef("educationOptions");
  }

  getRxAnalysisOptions() {
    return this.getRef("rxAnalysisOptions");
  }

  getSpecialTestHeadings() {
    return this.getRef("specialTestHeadings");
  }

  getContraceptionTypes() {
    return this.getRef("contraceptionTypes");
  }

  addEncounter(data: AddEncounterDto): Promise<EncounterDto> {
    return this.api
      .post<EncounterDto>(`/patients/${data.patientId}/encounters`, data)
      .then(x => x.data);
  }

  addAmendment(data: AddAmendmentDto): Promise<AmendmentDto> {
    return this.api
      .post<AmendmentDto>(`/encounters/${data.encounterId}/amendments`, data)
      .then(x => x.data);
  }

  getAmendments(encounterId: string): Promise<AmendmentDto[]> {
    return this.api
      .get<AmendmentDto[]>(`/encounters/${encounterId}/amendments`)
      .then(x => x.data);
  }

  updateEncounter(data: PatchEncounterDto): Promise<EncounterDto> {
    return this.api
      .patch<EncounterDto>(`/encounters/${data.id}`, data, {
        headers: {
          [IF_MATCH_HEADER]: data.eTag
        }
      })
      .then(x => x.data);
  }

  updateEncounterTimer(
    data: EncounterTimerOperationDto
  ): Promise<EncounterTimerOperationDto> {
    return this.api
      .put<EncounterTimerOperationDto>(
        `/encounterTimerOperation/${data.id}`,
        data
      )
      .then(x => x.data);
  }

  getEncounterTimer(id: string): Promise<EncounterTimerDto> {
    return this.api
      .get<EncounterTimerDto>(`/encounterTimer/${id}`)
      .then(x => x.data);
  }

  getStructuredNote(
    request: GetStructuredNoteDto
  ): Promise<StructuredNoteDto[]> {
    const queryString = {
      types: request.types,
      encounterId: request.encounterId
    };
    return this.api
      .get<StructuredNoteDto[]>(
        `/encounters/${request.encounterId}/clinicalDataNotes?${stringify(
          queryString
        )}`
      )
      .then(x => x.data);
  }

  getEncounterClinicalNote(
    encounterId: string
  ): Promise<EncounterClinicalNoteDto> {
    return this.api
      .get<EncounterClinicalNoteDto>(`/encounter/${encounterId}/note`)
      .then(x => x.data);
  }

  updateEncounterClinicalNote(
    data: ClinicalNoteSectionElement[],
    encounterId: string
  ): Promise<EncounterClinicalNoteDto> {
    return this.api
      .put<EncounterClinicalNoteDto>(`/encounter/${encounterId}/note`, data)
      .then(x => x.data);
  }

  deleteEncounter(
    id: string,
    reasonForDiscard: string,
    reasonForDiscardComment: string
  ): Promise<void> {
    const queryString = {
      reasonForDiscard,
      reasonForDiscardComment
    };
    return this.api.delete(`/encounters/${id}?${stringify(queryString)}`);
  }

  discardEpisodeClinicalData(encounterId: string): Promise<void> {
    return this.api.delete(`/encounters/${encounterId}/episodeClinicalData`);
  }

  getInteractions(
    request: GetInteractionsDto
  ): Promise<QueryResult<InteractionDto>> {
    const queryString = withPaging(request);
    return this.api
      .get<InteractionDto[]>(
        `/patients/${request.patientId}/interactions?${stringify(queryString)}`,
        {
          headers: {
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
            Expires: "0"
          }
        }
      )
      .then(toQueryResult);
  }

  getEncounters(request: GetEncountersDto): Promise<QueryResult<EncounterDto>> {
    const { patientId, ...requestRest } = request;
    const queryString = withPaging(requestRest);
    return this.api
      .get<EncounterDto[]>(
        `/patients/${patientId}/encounters?${stringify(queryString)}`
      )
      .then(toQueryResult);
  }

  getEncounterSearch(
    request: GetEncountersDto
  ): Promise<QueryResult<EncounterSearchDto>> {
    const { patientId, ...requestRest } = request;
    const queryString = withPaging(requestRest);
    return this.api
      .get<EncounterDto[]>(
        `/patients/${patientId}/encounterSearch?${stringify(queryString)}`
      )
      .then(toQueryResult);
  }

  getPatientPastReasonForVisits(
    request: GetReasonForVisitClinicalDto
  ): Promise<QueryResult<CodedText>> {
    const { patientId, ...requestRest } = request;
    const queryString = withPaging(requestRest);
    const result = this.api
      .get<CodedText[]>(
        `/patients/${patientId}/reasonForVisits?${stringify(queryString)}`
      )
      .then(toQueryResult);
    return result;
  }

  getPatientProviders(patientId: string): Promise<UserIdentifier[]> {
    return this.api
      .get<UserIdentifier[]>(`/patients/${patientId}/providers`)
      .then(x => x.data);
  }

  getQuestionnaires(code: string): Promise<QuestionnaireDto> {
    return this.api
      .get<QuestionnaireDto>(`/questionnaires/${code}`)
      .then(x => x.data);
  }

  getSpecialTests(code: string): Promise<SpecialTestDto> {
    return this.api
      .get<SpecialTestDto>(`/specialTests/${code}`)
      .then(x => x.data);
  }

  getEncounterTypes() {
    return this.api
      .get<RefEncounterTypeDto[]>("/ref/encounterTypes")
      .then(x => x.data);
  }

  getSexuality() {
    return this.api.get<RefSexualityDto[]>("/ref/sexuality").then(x => x.data);
  }

  getRelationshipStatuses() {
    return this.getRef("relationshipStatuses");
  }

  getEncounterLocations() {
    return this.getRef("encounterLocations");
  }

  getEncounterStatuses() {
    return this.getRef("encounterStatuses");
  }

  getAccommodations() {
    return this.getRef("accommodations");
  }

  getLivesWith() {
    return this.getRef("livesWith");
  }

  getRelationships() {
    return this.getRef("relationships");
  }

  getImagingModalities() {
    return this.getRef<ImagingModalityRefDataDto>("imagingModalities");
  }

  getImagingRegions() {
    return this.getRef<ImagingRegionsRefDataDto>("imagingRegions");
  }

  getSafetyInHome() {
    return this.getRef("safetyInHome");
  }

  getIntakeUnits() {
    return this.getRef<IntakeFlagRefDataDto<IntakeUnit>>("intakeUnits");
  }

  private getRef<T extends RefDataDto = RefDataDto>(name: string) {
    return this.api
      .get<T[]>(`/ref/${name}?${withoutFields(excludedRefDataFields)}`)
      .then(x => x.data);
  }

  getTerminologiesSearch(
    request: TerminologySearchRequest
  ): Promise<QueryResult<TerminologyConceptDto>> {
    return this.api
      .get<TerminologyConceptDto[]>(
        `/terminology/$search?${stringify(withPaging(request))}`
      )
      .then(toQueryResult);
  }

  getTerminologyLookup(
    request: TerminologyLookupRequest[]
  ): Promise<TerminologyConceptDto[]> {
    return this.api
      .post<TerminologyConceptDto[]>("/terminology/$lookup?", request)
      .then(x => x.data);
  }

  getReactionTypes() {
    return this.api
      .get<RefReactionTypeDto[]>("/ref/reactionTypes")
      .then(x => x.data);
  }

  getReactionWarnings(args: GetReactionWarningsArgs) {
    return this.api
      .get<ReactionWarningDto[]>(
        `/patients/${args.patientId}/prescribingwarning?packId=${args.packId}`
      )
      .then(x => x.data);
  }

  getReactionWarningTypes() {
    return this.getRef("prescribingWarningTypes");
  }

  getReactionCertainties() {
    return this.getRef("reactionCertainties");
  }

  getEncounterClinicalData(
    data: GetEncounterClinicalDataDto
  ): Promise<EncounterClinicalDataDto> {
    const { encounterId, ...params } = data;
    return this.api
      .get<EncounterClinicalDataDto>(
        `/encounters/${encounterId}/clinicalData?${stringify(params)}`,
        {
          params
        }
      )
      .then(x => x.data);
  }

  getPatientClinicalData(
    data: GetPatientClinicalDataDto
  ): Promise<EncounterClinicalDataDto> {
    const { patientId, ...otherParams } = data;
    const queryString = withPaging(otherParams);
    return this.api
      .get<EncounterClinicalDataDto>(
        `/patients/${patientId}/clinicalData?${stringify(queryString)}`
      )
      .then(x => x.data);
  }

  async getEpisodeOfCareClinicalData(
    data: GetEpisodeOfCareClinicalDataDto
  ): Promise<EncounterClinicalDataDto> {
    const { patientId, ...queryString } = data;
    const x = await this.api.get<EncounterClinicalDataDto>(
      `/patients/${patientId}/episodeClinicalData?${stringify(queryString)}`
    );
    return x.data;
  }

  async getEpisodesOfCareClinicalData(
    data: GetEpisodesOfCareClinicalDataDto
  ): Promise<EpisodeOfCareClinicalDataDto[]> {
    const { patientId, ...queryString } = data;
    const x = await this.api.get<EpisodeOfCareClinicalDataDto[]>(
      `/patients/${patientId}/episodesOfCare/clinicaldata?${stringify(
        queryString
      )}`
    );
    return x.data;
  }

  getSidesOfBody() {
    return this.getRef("sideOfBody");
  }

  getPsfsReasons() {
    return this.getRef("psfsReasons");
  }

  getCareTypes() {
    return this.getRef("careTypes");
  }

  getPsfsCloseReasons() {
    return this.getRef("psfsCloseReasons");
  }

  getClinicalNoteSections() {
    return this.getRef("clinicalNoteSections");
  }

  getClinicalDataTypes() {
    return this.api
      .get<RefClinicalDataTypeDto[]>("/ref/clinicalDataTypes")
      .then(x => x.data);
  }

  updateClinicalData(encounterId: string, data: EncounterClinicalDataDto) {
    return this.api
      .put<EncounterClinicalDataDto>(`/encounters/${encounterId}/data`, data)
      .then(x => x.data);
  }

  getGeneralHealthConditions() {
    return this.getRef("generalHealthConditions");
  }

  getClinicalFlags() {
    return this.getRef<ClinicalFlagsDto>("clinicalFlags");
  }

  getNerves() {
    return this.getRef<NervesDto>("nerves");
  }

  getDermatomeEstimations() {
    return this.getRef("dermatomeEstimations");
  }

  getDermatomePivotTests() {
    return this.getRef("dermatomePivotTests");
  }

  getReflexNerves() {
    return this.getRef("reflexNerves");
  }

  getSpecialQuestions() {
    return this.getRef("specialQuestions");
  }

  getVertebrobasilarInsufficiencies() {
    return this.getRef("vertebrobasilarInsufficiencies");
  }

  getInjuryAreas() {
    return this.getRef<RefDataDto<InjuryArea>>("injuryAreas");
  }

  getDischargeStatus() {
    return this.getRef("dischargeStatuses");
  }

  getDischargers() {
    return this.getRef("dischargers");
  }

  getDischargeOutcomes() {
    return this.getRef("dischargeOutcomes");
  }

  getOutcomeDetails() {
    return this.getRef("outcomeDetails");
  }

  getDominantHands() {
    return this.getRef("dominantHands");
  }

  getSleepQualities() {
    return this.getRef("sleepQualities");
  }

  getSleepPositions() {
    return this.getRef("sleepPositions");
  }

  getSleepTimes() {
    return this.getRef("sleepTimes");
  }

  getPulseRhythms() {
    return this.getRef("pulseRhythms");
  }

  getBPPositions() {
    return this.getRef("bpPositions");
  }

  getTemperatureMethods() {
    return this.getRef("temperatureMethods");
  }

  getSnoreOptions() {
    return this.getRef("snoreOptions");
  }

  getBodyAreas() {
    return this.getRef("bodyAreas");
  }

  getMotionTypes() {
    return this.getRef<RefMotionTypeDto>("motionTypes");
  }

  getInjuryAreaMotionTypeGroups() {
    return this.getRef<RefInjuryAreaMotionTypeGroupDto>(
      "injuryAreaMotionTypeGroups"
    );
  }

  getClinicalTaskTypes() {
    return this.getRef<RefClinicalTaskTypeDto>("clinicalTaskTypes");
  }

  getClinicalActivityTypes() {
    return this.getRef<RefClinicalActivityTypeDto>("clinicalActivityType");
  }

  getClinicalActivityDescriptions() {
    return this.getRef<RefClinicalActivityDescriptionDto>(
      "clinicalActivityDescriptions"
    );
  }

  async getPtClinicalActivities(
    patientId: string
  ): Promise<ClinicalActivityDto[]> {
    return this.api
      .get<ClinicalActivityDto[]>(`patients/${patientId}/clinicalActivities`)
      .then(x => x.data);
  }

  async unlockClinicalActivities(unlockArgs: ClinicalActivityUnlockArgs) {
    return this.api
      .put<ClinicalActivityDto[]>("clinicalActivities/$unlock", unlockArgs)
      .then(x => x.data);
  }

  async getClinicalTaskPriorities() {
    return this.getRef("clinicalTaskPriorities");
  }

  async getPriorities() {
    return this.getRef("priorities");
  }

  async getPtClinicalTasks(patientId: string): Promise<ClinicalTaskDto[]> {
    return this.api
      .get<ClinicalTaskDto[]>(`patients/${patientId}/clinicalTasks`)
      .then(x => x.data);
  }

  async getClinicalTasks(
    request: GetClinicalTasksDto & PagingOptions
  ): Promise<QueryResult<ClinicalTaskDto>> {
    const queryString = withPaging(request);
    return this.api
      .get<ClinicalTaskDto[]>(`clinicalTasks?${stringify(queryString)}`)
      .then(x => toQueryResult(x));
  }

  async getClinicalActivities(
    request: GetClinicalActivitiesDto & PagingOptions
  ): Promise<QueryResult<ClinicalActivityDto>> {
    const queryString = withPaging(request);
    return this.api
      .get<ClinicalActivityDto[]>(
        `clinicalActivities?${stringify(queryString)}`
      )
      .then(x => toQueryResult(x));
  }

  async updateClinicalActivity(patientId: string, data: ClinicalActivityDto[]) {
    return this.api
      .put<ClinicalActivityDto[]>(
        `patients/${patientId}/clinicalActivities`,
        data
      )
      .then(x => x.data);
  }

  async updateClinicalTask(patientId: string, data: ClinicalTaskDto[]) {
    return this.api
      .put<ClinicalTaskDto[]>(`patients/${patientId}/clinicalTasks`, data)
      .then(x => x.data);
  }

  async unlockClinicalTasks(unlockArgs: ClinicalTasksUnlockArgs) {
    return this.api
      .put<ClinicalTaskDto[]>("clinicalTasks/$unlock", unlockArgs)
      .then(x => x.data);
  }

  async getClinicalTaskDeleteReasons() {
    return this.getRef("clinicalTaskDeleteReasons");
  }

  getStagingInfo(): Promise<GetStagingInfoDto> {
    return this.api
      .get<GetStagingInfoDto>("/documents/staging")
      .then(x => x.data);
  }

  async createDocuments(encounterId: string, options: DocumentCreateOptions) {
    await this.api.post<DocumentCreateOptions>(
      `/encounters/${encounterId}/documents`,
      options
    );
  }

  async addDocumentsByPatientId(
    patientId: string,
    options: DocumentCreateOptions
  ) {
    await this.api.post<DocumentCreateOptions>(
      `/patients/${patientId}/documents`,
      options
    );
  }

  getDocumentByDocumentId(
    patientId: string,
    documentId: string
  ): Promise<DocumentDto> {
    return this.api
      .get<DocumentDto>(`/patients/${patientId}/documents/${documentId}`)
      .then(x => x.data);
  }

  getDocuments(request: GetDocumentsDto): Promise<QueryResult<DocumentDto>> {
    const { patientId, ...requestRest } = request;
    const queryString = withPaging(requestRest);
    return this.api
      .get<DocumentDto[]>(
        `/patients/${patientId}/documents?${stringify(queryString)}`
      )
      .then(toQueryResult);
  }

  getDocumentUrl(args: GetDocumentUrlArgs): Promise<DocumentUrlDto> {
    const { patientId, documentId, ...restArgs } = args;
    const queryString =
      Object.keys(restArgs).length > 0 ? `?${stringify(restArgs)}` : "";

    return this.api
      .get<DocumentUrlDto>(
        `/patients/${patientId}/documents/${documentId}/url${queryString}`
      )
      .then(x => {
        return x.data;
      });
  }

  async updateDocument(
    encounterId: string,
    documentId: string,
    request: DocumentDto
  ) {
    return await this.api
      .put<DocumentDto>(
        `/encounters/${encounterId}/documents/${documentId}`,
        request
      )
      .then(x => x.data);
  }

  async deleteDocument(
    encounterId: string,
    documentId: string,
    args: DocumentDeleteArgsDto
  ) {
    const queryString = {
      ...args
    };
    await this.api.delete(
      `/encounters/${encounterId}/documents/${documentId}?${stringify(
        queryString
      )}`
    );
  }

  async moveDocument(
    encounterId: string,
    documentId: string,
    args: DocumentMoveArgsDto
  ) {
    const queryString = {
      ...args
    };
    await this.api.post(
      `/encounters/${encounterId}/documents/${documentId}/$move?${stringify(
        queryString
      )}`
    );
  }

  async getDocumentContent(
    patientId: string,
    documentId: string,
    contentType?: string | undefined
  ) {
    const query = stringify({ contentType });

    return await this.api
      .get<DocumentContentDto>(
        `/patients/${patientId}/documents/${documentId}/content?${query}`
      )
      .then(x => x.data);
  }

  addEpisodeOfCare(data: Partial<EpisodeOfCareDto>): Promise<EpisodeOfCareDto> {
    return this.api
      .post<EpisodeOfCareDto>(
        `/patients/${data.patientId}/episodesOfCare`,
        data
      )
      .then(x => x.data);
  }

  getEpisodeOfCare(episodeOfCareId: string): Promise<EpisodeOfCareDto> {
    return this.api
      .get<EpisodeOfCareDto>(`/episodesOfCare/${episodeOfCareId}`)
      .then(x => {
        return x.data;
      });
  }

  async deleteEpisodeOfCare(episodeOfCareId: string) {
    await this.api
      .delete<EpisodeOfCareDto>(`/episodesOfCare/${episodeOfCareId}`)
      .then(x => {
        return x.data;
      });
  }

  getPatientEpisodesOfCare(patientId: string): Promise<EpisodeOfCareDto[]> {
    return this.api
      .get<EpisodeOfCareDto[]>(`/patients/${patientId}/episodesOfCare`)
      .then(x => x.data);
  }

  async updateEpisodeOfCare(
    data: EpisodeOfCareDto,
    episodeOfCareId: string
  ): Promise<EpisodeOfCareDto> {
    return await this.api
      .put<EpisodeOfCareDto>(`episodesOfCare/${episodeOfCareId}`, data)
      .then(x => x.data);
  }

  async sendClinicalDocument(args: SendClinicalDocumentArgs): Promise<void> {
    await this.api.post<SendClinicalDocumentArgs>(
      `/documents/sendEmail?${stringify(args)}`
    );
  }

  getOnsets() {
    return this.getRef("onsets");
  }

  getDurations() {
    return this.getRef("durations");
  }

  getFrequencies() {
    return this.getRef("frequencies");
  }

  getFrequencyOccurrences() {
    return this.getRef("frequencyOccurrences");
  }

  getImpacts() {
    return this.getRef("impacts");
  }

  getHofPCAttributes() {
    return this.getRef("hofPCAttributes");
  }

  getPatientAppliedTreatments() {
    return this.getRef("patientAppliedTreatments");
  }

  getFamilyAliveStatuses(): Promise<RefDataDto<string>[]> {
    return this.getRef("familyAliveStatuses");
  }

  getSeverity() {
    return this.getRef("severity");
  }

  getChronicity() {
    return this.getRef("chronicity");
  }

  getFracture() {
    return this.getRef("fracture");
  }

  getFractureTypes() {
    return this.getRef("fractureTypes");
  }

  getBSLQualifiers() {
    return this.getRef("bslQualifiers");
  }

  getBodyHabitus() {
    return this.getRef("bodyHabitus");
  }
  getNails() {
    return this.getRef("nails");
  }
  getHands() {
    return this.getRef("hands");
  }
  getCyanosisPatterns() {
    return this.getRef("cyanosisPatterns");
  }
  getDiscolourations() {
    return this.getRef("discolourations");
  }
  getPulseMethods() {
    return this.getRef("pulseMethods");
  }
  getPulseCharacteristics() {
    return this.getRef("pulseCharacteristics");
  }
  getHydrations() {
    return this.getRef("hydrations");
  }

  getMeasurementTypes() {
    return this.getRef("measurementTypes");
  }

  getObservationTypes() {
    return this.getRef("observationTypes");
  }
}
